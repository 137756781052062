<template>
    <div id="app" class="container mb-3">

        <h1 class="my-3 text-center text-muted">Simple Simplicate Time-logging tool (SSTt)</h1>

        <div v-if="!authSecret || !authKey || (!user && !userLoading)">
            <h2 class="text-center mb-3">Hi there! This app needs to connect to your Simplicate account.</h2>
            <simplicate-setup :settings="settings" @change="onAuthChange" />
        </div>

        <div v-if="user">

        <section class="row g-3 mb-3">
            <div class="col" v-if="settings.pickDate">
                <input type="date" :value="dateValue" @input="onDateInput" :class="{'is-warning': !isToday}" class="form-control" tabindex="-1">
            </div>
            <div class="col-auto" v-if="settings.pickDate">
                <button type="button" @click="setToday" :disabled="isToday" class="btn btn-secondary">Today</button>
            </div>

            <div class="col-12">
                <!--:tabindex="1"-->
                <v-select
                    ref="projectSelector"
                    @hook:mounted="onSelectMounted('projectSelector')"
                    placeholder="Project"
                    required
                    v-model="project"
                    :options="projects"
                    label="name"
                    :reduce="p => p.id"
                    :filter="filterProjects"
                    :threshold="0.1"
                    :select-on-tab="true"
                    autofocus
                >
                    <template #option="option">
                        <code class="me-2">{{ option.project_number }}</code>
                        <span class="me-2">{{ option.project_name.replace(/\s*\(\d+\)$/, '') }}</span>
                        <span class="text-muted">{{ option.organization ? option.organization.name : '' }}</span>
                    </template>
                    <template #selected-option="option">
                        <code class="me-2">{{ option.project_number }}</code>
                        <span class="me-2">{{ option.project_name.replace(/\s*\(\d+\)$/, '') }}</span>
                        <span class="text-muted">{{ option.organization ? option.organization.name : '' }}</span>
                    </template>
                </v-select>
            </div>
            <div class="col-sm-4 col-md-5">
                <!--:tabindex="2"-->
                <v-select
                    ref="serviceSelector"
                    @hook:mounted="onSelectMounted('serviceSelector')"
                    placeholder="Dienst"
                    required
                    v-model="service"
                    :options="services"
                    label="name"
                    :reduce="s => s.id"
                    :select-on-tab="true"
                >
                    <template #no-options>
                        {{ project ? 'No options found' : 'Select a project first' }}
                    </template>
                </v-select>
            </div>
            <div class="col-sm-4 col-md-5">
                <!--:tabindex="3"-->
                <v-select
                    ref="hoursTypeSelector"
                    @hook:mounted="onSelectMounted('hoursTypeSelector')"
                    placeholder="Urensoort"
                    required
                    v-model="hoursType"
                    :options="hoursTypes"
                    :reduce="h => h.id"
                    :select-on-tab="true"
                >
                    <template #option="option">
                        <span class="color-label" :style="{ backgroundColor: option.color }"></span>
                        {{ option.label }}
                    </template>
                    <template #selected-option="option">
                        <span class="color-label" :style="{ backgroundColor: option.color }"></span>
                        {{ option.label }}
                    </template>
                    <template #no-options>
                        {{ project ? 'No options found' : 'Select a service first' }}
                    </template>
                </v-select>
            </div>
            <div class="col-sm-4 col-md-2">
                <hours-input
                    ref="hoursInput"
                    v-model="hours"
                    :display-mode="settings.hoursDisplayMode"
                    placeholder="Uren"
                />
            </div>
            <!-- FIXME: fix tab order when `commentBeforeJira` -->
            <div class="col-6" :style="{ order: settings.commentBeforeJira ? 3 : 2 }">
                <v-select
                    ref="jiraIssueSelector"
                    @hook:mounted="onSelectMounted('jiraIssueSelector')"
                    :placeholder="'Jira issue' + (!jiraUser ? ' - Not logged in' : '')"
                    v-model="jiraIssue"
                    :options="jiraIssues"
                    :reduce="i => i.key"
                    :filter="filterJiraSearch"
                    @search="onJiraSearch"
                    :select-on-tab="true"
                    :disabled="!jiraUser"
                >
                    <template #option="option">
                        <template v-if="option.key !== undefined">
                            <code class="me-2">{{ option.key }}</code>
                            <span class="me-2">{{ option.fields.summary.replace(`${option.key}: `, '') }}</span>
                            <span v-if="option.fields.customfield_10318 && option.fields.customfield_10318.length" class="text-muted">{{ option.fields.customfield_10318 }}</span>
                        </template>
                        <template v-else-if="option.label !== undefined">{{ option.label }}</template>
                        <template v-else>{{ option }}</template>
                    </template>
                    <template #selected-option="option">
                        <template v-if="option.key !== undefined">
                            <code class="me-2">{{ option.key }}</code>
                            <span class="me-2">{{ option.fields.summary.replace(`${option.key}: `, '') }}</span>
                            <span v-if="option.fields.customfield_10318 && option.fields.customfield_10318.length" class="text-muted">{{ option.fields.customfield_10318 }}</span>
                        </template>
                        <template v-else-if="option.label !== undefined">{{ option.label }}</template>
                        <template v-else>{{ option }}</template>
                    </template>
                    <template #no-options>
                        {{ project ? 'Start typing to search for a Jira issue' : 'Select a project first, or start typing to search' }}
                    </template>
                </v-select>
            </div>
            <div class="col-6" :style="{ order: settings.commentBeforeJira ? 2 : 3 }">
                <input type="text"
                       ref="commentInput"
                       placeholder="Toelichting"
                       v-model.trim="comment"
                       :required="settings.commentRequired"
                       class="form-control">
            </div>
            <div class="col-sm-6 order-4">
                <button type="submit" @click="submit" :disabled="!isValid || submitting" :class="id ? 'btn-warning' : 'btn-primary'" class="btn d-block w-100">
                    {{ id ? 'Submit changes' : 'Submit' }}
                </button>
            </div>
            <div class="col-sm-3 order-4">
                <button type="submit" @click="submitAndClear" :disabled="!isValid || submitting" :class="id ? 'btn-warning' : 'btn-primary'" class="btn btn-primary d-block w-100">
                    {{ id ? 'Submit changes & clear' : 'Submit & clear' }}
                </button>
            </div>
            <div class="col-sm-3 order-4">
                <button type="reset" @click="clear" :disabled="submitting"  class="btn btn-secondary d-block w-100">Clear</button>
            </div>
        </section>

        <hr>

        <div class="position-relative">
            <table v-if="loggedHours.length" class="table">
                <!-- TODO: use 'fixed' column widths to prevent annoying jumps when switching between days -->
                <thead>
                <tr>
                    <th>Project</th>
                    <th v-if="!settings.hideLoggedService">Dienst</th>
                    <th v-if="!settings.hideLoggedType">Urensoort</th>
                    <th style="width:0.1%">Uren</th>
                    <th>Toelichting</th>
                    <th style="width:0.1%">Jira issue</th>
                    <th style="width:0.1%">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="hours in loggedHours" :key="hours.id" :class="{'table-light': !id && hours.project.id === project, 'table-warning': id === hours.id}">
                    <td>
                        <span
                            :title="`${hours.project.project_number} - ${hours.project.organization.name} / ${hours.type.label} / ${hours.projectservice.name}`"
                        >{{ hours.project.name }}</span>
                    </td>
                    <td v-if="!settings.hideLoggedService">{{ hours.projectservice.name }}</td>
                    <td v-if="!settings.hideLoggedType" class="text-nowrap">
                        <span v-if="hours.type.color" class="color-label" :style="{ backgroundColor: hours.type.color }"></span>
                        {{ hours.type.label }}
                    </td>
                    <td class="text-nowrap">
                        {{ hours.hours.toFixed(2) }}<!-- TODO: use same formatting as in HoursInput -->
                        <div class="progress" style="height: 1px">
                            <div class="progress-bar" role="progressbar" :style="{width: `${ Math.round(100 * hours.hours / hoursTarget ) }%`}"></div> <!-- What to use? loggedHoursSum/loggedHoursMax/hoursTarget/...?-->
                        </div>
                    </td>
                    <td>{{ hours.note }}</td>
                    <td :class="{'table-warning': hours.jiraKey && jiraIssue && hours.jiraKey === jiraIssue}" class="text-nowrap">
                        <a v-if="hours.jiraKey" :href="hours.externalUrl || `${jiraUrl}/browse/${hours.jiraKey}`" class="text-nowrap" target="_blank" rel="nofollow noopener">{{ hours.jiraKey }}</a>
                        <a v-else-if="hours.externalUrl" :href="hours.externalUrl" target="_blank" rel="nofollow noopener">link</a> <!-- TODO: some last part of url? -->
                    </td>
                    <td class="text-nowrap">
                        <button type="button" @click="confirmDeleteHours(hours.id, hours)" :disabled="hours.locked" class="btn btn-sm btn-outline-danger" title="Remove entry"><i class="bi-trash"></i></button>
                        <button type="button" @click="editHours(hours)" :disabled="hours.locked" class="ms-1 btn btn-sm btn-outline-warning" title="Edit entry"><i class="bi-pencil"></i></button>
                        <button type="button" @click="importHours(hours)" class="ms-1 btn btn-sm btn-outline-secondary" title="Import entry"><i class="bi bi-box-arrow-in-up"></i></button>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td :colspan="1 + !settings.hideLoggedService + !settings.hideLoggedType"></td>
                        <td><strong>{{ loggedHoursSum.toFixed(2) }}</strong></td><!-- TODO: use same formatting as in HoursInput -->
                        <td colspan="3"></td>
                    </tr>
                </tfoot>
            </table>
            <div v-else-if="date" class="alert alert-info">
                No logged hours yet for {{ date.toDateString() }}.
            </div>
            <div v-else class="alert alert-warning">
                Select a valid date to see the logged hours.
            </div>

            <!-- TODO: Show hour values as well -->
            <div class="progress" :title="`${loggedHoursSum.toFixed(2)} of ${hoursTarget.toFixed(2)} hours logged`">
                <div class="progress-bar" role="progressbar" :style="{width: `${loggedHoursPercentage}%`}"></div>
                <div v-if="loggedOvertimePercentage" class="progress-bar bg-dark" role="progressbar" :style="{width: `${loggedOvertimePercentage}%`}"></div>
                <div v-if="hoursPercentage" class="progress-bar progress-bar-striped bg-warning" role="progressbar" :style="{width: `${hoursPercentage}%`}"></div>
            </div>

            <div v-if="hoursLoading" class="loading-overlay">
                <div class="spinner-container">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <footer class="d-flex justify-content-between text-muted">
            <nav>
                <a data-bs-toggle="modal" href="#settings" class="text-muted me-2">Settings</a>
                <a data-bs-toggle="modal" href="#project-mapping" class="text-muted mx-2">Project Mapping</a>
                <a data-bs-toggle="modal" href="#shortcuts" class="text-muted ms-2">Shortcuts</a>
            </nav>
            <div v-if="user">
                <div class="dropup">
                    Logged in as
                    <a href="#" class="text-muted" data-bs-toggle="dropdown" aria-expanded="false">
                        <span>{{ employee ? employee.name : user.username }}</span>
                        <img v-if="employee && employee.avatar && employee.avatar.url_small" :src="employee.avatar.url_small" class="rounded-circle ms-2" style="width: 2rem; height: 2rem" :alt="employee.avatar.initals">
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><a @click="confirmLogout" href="#" class="dropdown-item">Log out from Simplicate</a></li>
                    </ul>
                </div>
            </div>
        </footer>

        <nav v-if="settings.showEmployees && employees" class="d-flex flex-row flex-wrap mt-4">
            <button type="button" v-for="employee in employees" :key="employee.id" @click="employeeId = employee.id" :class="employeeId === employee.id ? 'btn-secondary' : 'btn-outline-light text-body'" class="btn me-2 mb-2">
                <img v-if="employee.avatar" :src="employee.avatar.url_small" class="rounded me-2" style="width: 2rem; height: 2rem" :style="{'background-color': employee.avatar.color}" :alt="employee.avatar.initals">
                <span>{{ employee.name }}</span>
            </button>
        </nav>

        </div>

        <div class="modal" id="settings">
            <div class="modal-dialog modal-lg modal-fullscreen-md-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Settings</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <settings v-model="settings"/>

                    </div>
                    <div class="modal-footer justify-content-center">
                        <em class="text-muted">Settings are saved automatically.</em>
                    </div>
                </div>
            </div>
        </div>

        <!-- FIXME: we must use a static backdrop and disable esc discarding to prevent v-select from triggering modal hide -->
        <div class="modal" id="project-mapping" ref="projectMappingModal" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-xl modal-fullscreen-lg-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Simplicate ↔ Jira Project Mapping</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <project-mapper v-model="projectMapping" :simplicate-projects="projects" :jira-projects="jiraProjects"/>

                    </div>
                    <div class="modal-footer justify-content-center">
                        <em class="text-muted">
                            These mappings are saved automatically.
                            Click <a href="#" data-bs-dismiss="modal">here</a> or the &times; in top right corner to close.
                        </em>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="shortcuts" ref="shortcutsModal">
            <div class="modal-dialog modal-lg modal-fullscreen-md-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Keyboard Shortcuts</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div class="alert alert-info">
                            <!-- TODO: document shortcuts -->
                            <strong>TODO</strong>... check <code>onKeyUp</code> source code for now ;)
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import axios from 'axios';
import {Modal} from 'bootstrap';
import Fuse from 'fuse.js';
import HoursInput from '@/components/HoursInput';
import Settings from '@/components/Settings';
import ProjectMapper from "@/components/ProjectMapper";
import SimplicateSetup from '@/components/SimplicateSetup';
import debounce from '@/lib/debounce';
import dateWeekNumber from "@/lib/dateWeekNumber";

const jiraUrl = process.env.VUE_APP_JIRA_URL;

const defaultSettings = {
    apiUrl: `${process.env.VUE_APP_SIMPLICATE_URL}/api/v2`,
    jiraApiUrl: `${jiraUrl}/rest/api/2`, // TODO: update code to use this setting (in stead of global var)
    projectsSort: 'project_name',
    jiraDebounce: 200, // ms
    jiraExternalIssueIdField: '10318', // TODO: update code to use this setting
    requireComment: true,
    autofocusProjectSelect: false,
    hoursDisplayMode: 'hm',
    preferredServiceRegex: null,
    preferredHoursTypeRegex: null,
    jiraSetStarted: false,
    confirmPosts: false,
    showEmployees: false,
    hideLoggedService: false,
    hideLoggedType: false,
};

export default {
    name: 'App',
    components: {ProjectMapper, SimplicateSetup, HoursInput, Settings},
    data () {
        return {

            debug: false,
            userDebug: false,

            authKey: window.localStorage.getItem('authKey') || process.env.VUE_APP_SIMPLICATE_AUTH_KEY || null,
            authSecret: window.localStorage.getItem('authSecret') || process.env.VUE_APP_SIMPLICATE_AUTH_SECRET || null,

            jiraUrl,
            jiraConfig: {
                withCredentials: true,
            },

            user: null,
            userLoading: false,
            employeeId: null,
            timeTables: [],
            loggedHours: [],
            hoursLoading: false,

            settings: Object.assign({}, defaultSettings, JSON.parse(window.localStorage.getItem('settings') || '{}')),

            projectMapping: JSON.parse(window.localStorage.getItem('projectMapping') || '[]')
                .filter(([s, j]) => s.length || j.length),

            id: null,
            date: new Date(),
            project: null,
            projects: [],
            service: null,
            serviceNext: null,
            services: [],
            servicesLoading: false,
            hoursType: null,
            hoursTypeNext: null,
            hoursTypes: [],
            hours: 0,
            jiraIssue: null,
            jiraIssues: [],
            jiraProjects: [],
            comment: null,

            submitting: false,

            employees: [],
            employeesLoading: false,

            jiraUser: null,
            jiraUserLoading: null,
        }
    },
    computed: {
        employee () {
            return this.user && this.user.employee_id ? this.employees.find(e => e.id === this.user.employee_id) : null;
        },
        bearer () {
            return this.authKey && this.authSecret ? `${this.authKey}:${this.authSecret}` : null;
        },
        apiConfig () {
            return {
                headers: {
                    'X-Experimental-ISO-8601': 'true',
                    'Content-Type': 'application/json',
                    'Authentication-Key': this.authKey,
                    'Authentication-Secret': this.authSecret,
                }
            }
        },
        dateValue () {
            return this.dateObjectToStr(this.date);
        },
        weekOddness () {
            const [,week] = this.date ? dateWeekNumber(this.date) : [];
            if (week === null) {
                return null;
            }
            return week % 2 === 0 ? 'even' : 'odd';
        },
        isValid () {
            return this.date &&
                this.project &&
                this.hoursType &&
                this.hours > 0 &&
                this.service &&
                (!this.settings.requireComment || this.comment);
        },
        isToday () {
            const now = new Date();
            return this.date &&
                this.date.getDate() === now.getDate() &&
                this.date.getMonth() === now.getMonth() &&
                this.date.getFullYear() === now.getFullYear();
        },
        state () {
            return {
                id: this.id,
                date: this.dateValue,
                project: this.project,
                service: this.service,
                hoursType: this.hoursType,
                hours: this.hours,
                jiraIssue: this.jiraIssue,
                comment: this.comment,
            };
        },
        jiraProjectsSelected () {
            if (this.project && this.projectMapping) {
                return this.projectMapping
                    .filter(([s,]) => s.includes(this.project))
                    .flatMap(([,j]) => j.map(id => this.jiraProjects.find(jO => jO.id === id)))
            }
            return [];
        },
        jiraProjectKeys () {
            let keys = [];
            // TODO: add setting to control if all projectKeys are used, or only the current one.
            if (this.jiraProjectsSelected.length) {
                keys = this.jiraProjectsSelected
                    .flatMap(p => p.projectKeys);
            }
            return keys.length ? keys : this.jiraProjects.flatMap(p => p.projectKeys);
        },
        hoursTarget () {
            if (!this.date || !this.weekOddness) {
                return 0;
            }
            const timeTable = this.timeTables.find(tt => {
                const startDate = this.dateStrToObject(tt.start_date);
                const endDate = this.dateStrToObject(tt.end_date);
                return startDate <= this.date
                    && (endDate == null || endDate >= this.date);
            });
            if (!timeTable || !timeTable[`${this.weekOddness}_week`]) {
                return 0;
            }
            const dayNr = this.date.getDay() || 7; // sunday 0 -> 7
            return timeTable[`${this.weekOddness}_week`][`day_${dayNr}`].hours;
        },
        loggedHoursSum () {
            const sum = this.loggedHours.reduce((sum, hours) => {
                return sum + (this.id && hours.id === this.id ? 0 : hours.hours);
            }, 0);
            return Math.round(sum * 60) / 60;
        },
        loggedHoursMax () {
            return Math.max(...this.loggedHours.map(h => h.hours));
        },
        loggedHoursPercentage () {
            return this.hoursTarget ? (this.loggedHoursSum - this.loggedOvertime) / this.hoursTarget * 100 : 0;
        },
        hoursPercentage () {
            return this.hours && this.hoursTarget ? (this.hours / this.hoursTarget * 100) : null;
        },
        loggedOvertime () {
            return Math.max(0, this.loggedHoursSum - this.hoursTarget);
        },
        loggedOvertimePercentage () {
            return this.hoursTarget ? this.loggedOvertime / this.hoursTarget * 100 : 0;
        },
        preferredServiceRegexes () {
            return this.getRegexes(this.settings.preferredServiceRegex);
        },
        preferredHoursTypeRegexes () {
            return this.getRegexes(this.settings.preferredHoursTypeRegex);
        },
    },
    watch: {
        user () {
            if (this.user) {
                this.employeeId = this.user.employee_id;
                this.fetchHours();
                this.fetchTimetable();
            }
            if (this.user && this.projects.length === 0) {
                this.fetchProjects();
            }
        },
        dateValue () {
            this.fetchHours();
        },
        employeeId (value, prev) {
            if (value && prev) {
                this.fetchHours();
                this.fetchTimetable();
            }
        },
        project () {
            if (this.project) {
                this.fetchServices();
            } else {
                this.services = [];
            }
        },
        jiraProjectsSelected (projects) {
            if (projects.length > 0 && projects.length < 10) {
                const jql = `project IN (${this.jiraProjectsSelected.map(p => p.key).join(',')}) ORDER BY updated DESC`;
                const params = new URLSearchParams({
                    jql,
                    fields: 'summary,customfield_10318', // TODO: use setting?
                    maxResults: 200, // TODO: use setting
                });
                this.debounceFetchJiraIssues(params, () => {});
            }
        },
        services () {
            if (this.service && this.services.find(s => s.id === this.service) !== undefined) {
                return;
            }
            const service = (() => {
                if (this.services.length === 0) {
                    return null;
                }
                if (this.services.length === 1) {
                    return this.services.slice(0, 1)[0];
                }
                for (const regex of this.preferredServiceRegexes) {
                    for (const service of this.services) {
                        if (regex.test(service.name)) {
                            return service;
                        }
                    }
                }
                // TODO: keep service if it's the 'same' type (?)
                return null;
            })();
            this.service = service ? service.id : null;
        },
        service () {
            if (this.service) {
                this.fetchHoursTypes();
            } else {
                this.hoursTypes = [];
            }
        },
        hoursTypes () {
            if (this.hoursType && this.hoursTypes.find(s => s.id === this.hoursType) !== undefined) {
                return;
            }
            const hoursType = (() => {
                if (this.hoursTypes.length === 0) {
                    return null;
                }
                if (this.hoursTypes.length === 1) {
                    return this.hoursTypes.slice(0, 1)[0];
                }
                for (const regex of this.preferredHoursTypeRegexes) {
                    for (const hoursType of this.hoursTypes) {
                        if (regex.test(hoursType.name)) {
                            return hoursType;
                        }
                    }
                }
                // TODO: keep hours-type if it's the 'same' type (?)
                return null;
            })();
            this.hoursType = hoursType ? hoursType.id : null;
        },
        settings: {
            deep: true,
            handler (settings) {
                window.localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        projectMapping: {
            deep: true,
            handler (mapping) {
                window.localStorage.setItem('projectMapping', JSON.stringify(mapping));
            }
        },
        state () {
            if (!this.id) {
                window.history.replaceState(this.state, 'SSTt ' + this.project); // TODO: use new id?
            }
        },
    },
    methods: {
        confirmLogout() {
            if (window.confirm('Are you sure you want to log out from Simplicate?\n\n(This will require you to re-authorize this application if you want use it again)')) {
                this.logout();
            }
        },
        logout () {
            this.user = null;
            this.employeeId = null;
            this.authKey = null;
            this.authSecret = null;
            this.storeAuth();
            // TODO: check if we can revoke the used auth token
        },
        async submit (clearing) {
            if (!this.settings.pickDate) {
                this.setToday();
            }
            if (!this.isValid) {
                return;
            }
            if (this.id && this.jiraIssue && !confirm('This entry is linked to an Jira issue, which will not be updated!\nAre you sure you want to continue?')) {
                return;
            }
            this.submitting = true;
            try {
                await this.submitHours();
                if (this.jiraIssue && !this.id) { // TODO: allow updating/posting jiraWorklog
                    await this.postJiraWorklog();
                }
            } catch (error) {
                window.alert('An error occurred\n(check console for details)');
                console.error(error);
                this.submitting = false;
                return;
            }
            window.history.pushState(this.state, 'SSTt ' + this.project); // TODO: use new id?
            this.id = null;
            this.jiraIssue = null;
            this.comment = null;
            this.hours = 0;
            this.submitting = false;
            if (!clearing) {
                this.$nextTick(() => {
                    this.focusHoursInput();
                });
            }
            this.fetchHours(); // TODO: 'manually' add the just created hours record to the loggedHours array. (one less request)
        },
        submitAndClear () {
            if (!this.isValid) {
                return;
            }
            this.submit();
            this.clear();
            this.$nextTick(() => {
                this.focusProjectSelect();
            });
        },
        clear () {
            this.restoreState({
                date: this.date,
            });
            // TODO: make configurable if date is reset to today
            // this.setToday();
        },
        confirmDeleteHours (id, hours) {
            // TODO: warn user if Jira issue is set.
            if (!confirm('Are you sure you want to remove this entry?')) {
                return;
            }
            this.deleteHours(id, hours);
        },
        editHours (hours) {
            this.importHours(hours, {
                id: hours.id,
            });
        },
        importHours (hours, ...more) {
            this.restoreState(Object.assign({
                date: this.dateValue, // TODO: extract date from hours.started?
                project: hours.project ? hours.project.id : null,
                service: hours.projectservice ? hours.projectservice.id : null,
                hoursType: hours.type ? hours.type.id : null,
                hours: hours.hours,
                jiraIssue: hours.jiraKey,
                comment: hours.note,
            }, ...more));
        },
        restoreState (state) {
            this.id = state.id || null;
            this.date = this.dateStrToObject(state.date || null);
            this.project = state.project || null;
            this.service = state.service || null;
            this.serviceNext = this.service;
            this.hoursType = state.hoursType || null;
            this.hoursTypeNext = this.hoursType;
            this.hours = state.hours || 0;
            this.jiraIssue = state.jiraIssue || null;
            this.comment = state.comment || null;
        },
        storeAuth () {
            localStorage.setItem('authKey', this.authKey);
            localStorage.setItem('authSecret', this.authSecret);
        },
        setToday () {
            this.date = new Date();
        },
        dateObjectToStr (value) {
            if (!value) {
                return null;
            }
            const mm = ('' + (value.getMonth() + 1)).padStart(2, '0');
            const dd = ('' + value.getDate()).padStart(2, '0');
            return `${value.getFullYear()}-${mm}-${dd}`;
        },
        dateStrToObject (value) {
            return value ? new Date(value) : null;
        },
        onDateInput (event) {
            const value = event.target.value;
            this.date = this.dateStrToObject(value);
        },
        filterProjects (options, search) {
            const fuse = new Fuse(options, {
                keys: [{
                    name: 'project_name',
                    weight: 0.7,
                },{
                    name: 'organization.name',
                    weight: 0.3,
                }],
            });
            return search.length
                ? fuse.search(search).map(({item}) => item)
                : fuse.list;
        },
        filterJiraSearch (options, search) {
            const fuse = new Fuse(options, {
                keys: ['key', 'fields.summary'],
                shouldSort: true,
                // includeScore: true,
            });
            return search.length
                ? fuse.search(search).map(({item}) => item)
                : fuse.list;
        },
        onJiraSearch (search, loading) {
            if (search) {
                // TODO: finish this stuff :)
                if (this.jiraProjectsSelected.length && this.jiraProjectsSelected.length <= 10) {
                    // const jql = `project IN (${this.jiraProjectsSelected.map(p => p.key).join(',')}) ORDER BY updated DESC`;
                    // const params = new URLSearchParams({
                    //     jql,
                    //     fields: 'summary,customfield_10318', // TODO: use setting?
                    //     maxResults: 200, // TODO: use setting
                    // });
                    // this.debounceFetchJiraIssues(params, loading);

                    loading(false);
                    return;
                }
                const params = this.getJiraIssueSearchPrams(search);
                this.debounceFetchJiraIssues(params, loading);
            } else {
                // TODO: cancel current fetch request (if exists)
                loading(false);
            }
        },
        onPopState (event) {
            if (event.state) {
                this.restoreState(event.state);
            } else {
                this.clear();
            }
        },
        onKeyUp (event) {
            if (event.key === 'Enter' && (event.ctrlKey) && this.isValid) {
                if (event.shiftKey) {
                    this.submitAndClear();
                } else {
                    this.submit();
                }
            } else if (event.key === '?' && event.target === document.body) {
                const settingsModal = Modal.getInstance(this.$refs.shortcutsModal)
                    || new Modal(this.$refs.shortcutsModal, {
                        keyboard: true,
                    });
                settingsModal.toggle();
            } else if (event.key === 'p' && (event.altKey || event.target === document.body)) {
                event.preventDefault();
                this.focusProjectSelect();
            } else if (event.key === 'i' && (event.altKey || event.target === document.body)) {
                event.preventDefault();
                this.focusServiceSelect();
            } else if (event.key === 'u' && (event.altKey || event.target === document.body)) {
                event.preventDefault();
                this.focusHoursTypeSelect();
            } else if (event.key === 'j' && (event.altKey || event.target === document.body)) {
                event.preventDefault();
                this.focusJiraIssueSelect();
            } else if (event.key === 'o' && (event.altKey || event.target === document.body)) {
                event.preventDefault();
                this.focusCommentInput();
            } else if (event.key === 't' && (event.altKey || event.target === document.body)) {
                event.preventDefault();
                this.setToday();
            } else if (event.key === 'ArrowLeft' && event.altKey && event.shiftKey) {
                event.preventDefault();
                this.stepDate(event.ctrlKey ? -7 : -1);
            } else if (event.key === 'ArrowRight' && event.altKey && event.shiftKey) {
                event.preventDefault();
                this.stepDate(event.ctrlKey ? +7 : +1);
            }
        },
        onAuthChange (authKey, authSecret) {
            this.authKey = authKey;
            this.authSecret = authSecret;
            this.fetchUser();
        },
        onSelectMounted(ref) {
            // Prevent tabbing to the clear button of the vue-select input
            const $ref = this.$refs[ref];
            if ($ref.$refs !== undefined && $ref.$refs.clearButton !== undefined) {
                $ref.$refs.clearButton.tabIndex = -1;
            }
        },
        fetchUser () {
            this.userLoading = true;
            axios.get(this.settings.apiUrl + '/users/user', this.apiConfig).then(resp => {
                if (resp.data.data && !resp.data.errors) {
                    this.user = resp.data.data;
                    this.storeAuth();
                }
            }).finally(() => {
                this.userLoading = false;
            });
        },
        fetchTimetable () {
            if (!this.employeeId) {
                throw new Error('user employee_id must de available');
            }
            const params = new URLSearchParams({
                'sort': '-start_date',
                'q[employee.id]': this.employeeId,
            });
            // TODO: check if we still need this. (don't think so)
            // if (this.dateValue) {
            //     params.set('q[start_date][le]', this.dateValue);
            //     params.set('q[end_date][ge]', this.dateValue);
            //     params.set('q[end_date]', 'null');
            // }
            axios.get(this.settings.apiUrl + '/hrm/timetable?' + params, this.apiConfig).then(resp => {
                if (resp.data.data && !resp.data.errors) {
                    this.timeTables = resp.data.data;
                }
            });
        },
        fetchProjects () {
            if (!this.user || !this.employeeId) {
                throw new Error('user employee_id must de available');
            }
            const params = new URLSearchParams({
                'sort': 'project_name', // [start_date, name, project_name, project_number, ...?]
                'q[employee_id]': this.employeeId,
            });
            axios.get(this.settings.apiUrl + '/hours/projects?' + params, this.apiConfig).then(resp => {
                if (resp.data.data && !resp.data.errors) {
                    this.projects = resp.data.data;
                }
            });
        },
        fetchServices () {
            if (!this.user || !this.employeeId || !this.project) {
                throw new Error('user employee_id and project-id must de available');
            }
            const params = new URLSearchParams({
                // 'sort': 'start_date',
                'q[employee_id]': this.employeeId,
                // 'q[start_date]': this.dateValue,
                'q[project_id]': this.project,
            });
            this.servicesLoading = true;
            axios.get(this.settings.apiUrl + '/hours/projectservices?' + params, this.apiConfig)
                .then(resp => {
                    if (resp.data.data && !resp.data.errors) {
                        this.services = resp.data.data;
                    }
                })
                .catch((err) => {
                    this.services = [];
                    throw err;
                })
                .finally(() => {
                    this.servicesLoading = false;
                });
        },
        fetchHoursTypes () {
            if (!this.user || !this.employeeId || !this.project || !this.service) {
                throw new Error('user employee_id, project_id and projectservice_id must de available');
            }
            const params = new URLSearchParams({
                // 'sort': 'start_date',
                'q[employee_id]': this.employeeId,
                // 'q[start_date]': this.dateValue,
                'q[project_id]': this.project,
                'q[projectservice_id]': this.service,
            });
            this.hoursTypesLoading = true;
            axios.get(this.settings.apiUrl + '/hours/projectservicehourstypes?' + params, this.apiConfig)
                .then(resp => {
                    if (resp.data.data && !resp.data.errors) {
                        this.hoursTypes = resp.data.data;
                    }
                })
                .finally(() => {
                    this.hoursTypesLoading = false;
                });
        },
        fetchHours() {
            if (!this.user || !this.employeeId || !this.date) {
                throw new Error('user employee_id and date must de available');
            }

            const params = new URLSearchParams({
                'sort': 'created_at',
                'q[employee.id]': this.employeeId,
                'q[start_date]': `${this.dateValue} *`,
                'limit': 200,
            });

            this.hoursLoading = true;

            const copyProps = ['id', 'project', 'projectservice', /*'hours', */'start_date', 'is_time_defined', 'note', 'status', 'locked'];

            axios.get(this.settings.apiUrl + '/hours/hours?' + params, this.apiConfig)
                .then(resp => {
                    // console.debug(resp.data);
                    if (resp.data.data && !resp.data.errors) {
                        this.loggedHours = resp.data.data.map(entry => {
                            const externalUrlFields = entry.custom_fields.filter(f => f.name === 'external_url' && f.value);
                            const externalUrl = externalUrlFields.length ? externalUrlFields[0].value : null;
                            const jiraKeyMatch = externalUrl ? externalUrl.match(/\/browse\/([A-Z0-9]+-\d+)/i) : null;
                            const jiraKey = jiraKeyMatch ? jiraKeyMatch[1] : null;

                            // copy some props from the 'type' object
                            const type = (({ id, label, color }) => ({ id, label, color }))(entry.type);

                            const hours = Math.round((entry.hours || 0) * 60) / 60;

                            return Object.assign(
                                {},
                                copyProps.reduce((result, prop) => { result[prop] = entry[prop]; return result }, {}),
                                {
                                    hours,
                                    type,
                                    externalUrl,
                                    jiraKey,
                                },
                            );
                        });
                    }
                })
                .finally(() => {
                    this.hoursLoading = false;
                });
        },
        fetchAllEmployees () {
            const params = new URLSearchParams({
                'sort': 'name',
                'q[employment_status]': 'active',
            });

            this.employeesLoading = true;

            axios.get(this.settings.apiUrl + '/hrm/employee?' + params, this.apiConfig)
                .then(resp => {
                    // console.debug(resp.data);
                    if (resp.data.data && !resp.data.errors) {
                        this.employees = resp.data.data.filter(e => e.name != null && e.name !== '');
                    }
                })
                .finally(() => {
                    this.employeesLoading = false;
                });
        },
        getJiraIssueSearchPrams (search) {
            //jql=summary~%27TDS%27%20ORDER%20BY%20key&fields=summary,created&maxResults=100&sort=foo
            const searchOr = [];
            const numberMatch = search.match(/^\d{2,}$/);
            if (numberMatch && this.jiraProjectKeys.length >= 1 && this.jiraProjectKeys.length < 10) {
                searchOr.push(`key IN (${this.jiraProjectKeys.map(k => `${k}-${search}`)})`);
            } else {
                searchOr.push(`text ~ '${escape(search)}'`);
            }
            const keyMatch = search.match(/^([A-Z]+)-\d+$/i);
            if (keyMatch && this.jiraProjectKeys.includes(keyMatch[1].toUpperCase())) {
                searchOr.push(`key = ${escape(search)}`);
            }
            // External issue id :D
            if (search.match(/^([A-Z]+-\d+|\d{5,})$/i)) {
                searchOr.push(`cf[10318] ~ ${escape(search)}`);
            }
            const projectMatch = search.match(/^[A-Z]{3,}\d*\b/);
            if (projectMatch && this.jiraProjectKeys.includes(projectMatch[0])) {
                searchOr.push(`project = ${escape(search)}`);
            }
            return new URLSearchParams({
                jql: searchOr.join(' OR '),
                fields: 'summary,customfield_10318', // TODO: use setting?
                maxResults: 200, // TODO: use setting
            });
        },
        async fetchJiraIssues (params, loading) {
            return axios.get(this.settings.jiraApiUrl + '/search?' + params, this.jiraConfig)
                .then(resp => {
                    // FIXME: The X-AUSERNAME header isn't whitelisted in Access-Control-Expose-Headers
                    const username = resp.headers['x-ausername'] || null;
                    if (/*!username || */username === 'anonymous') {
                        this.jiraUser = null;
                        throw new Error('Not logged in at Jira');
                    }
                    if (resp.data && resp.data.issues) {
                        this.jiraIssues = resp.data.issues.map(item => {
                            return Object.assign({}, item, {
                                label: `${item.key} ${item.fields.customfield_10318 || ''} ${item.fields.summary}`,
                            });
                        });
                    } else {
                        this.jiraIssues = [];
                    }
                })
                .finally(() => {
                    loading(false);
                });
        },
        fetchJiraProjects () {
            const params = new URLSearchParams({
                expand: 'projectKeys',
                maxResults: 1000, // TODO: use setting, also I believe 200 is the max :P
            });
            axios.get(this.settings.jiraApiUrl + '/project?' + params, this.jiraConfig)
                .then(resp => {
                    this.jiraProjects = resp.data;
                })
        },
        fetchJiraUser () {
            this.jiraUserLoading = true;
            axios.get(this.settings.jiraApiUrl + '/myself', this.jiraConfig)
                .then(resp => {
                    this.jiraUser = resp.data;
                })
                .catch((err) => {
                    console.error('Failed to load Jira user, not logged in?', err);
                    this.jiraUser = null;
                })
                .finally(() => {
                    this.jiraUserLoading = false;
                });
        },
        submitHours () {
            if (!this.isValid) {
                throw new Error('Form must be valid before it can be submitted to Simplicate');
            }
            if (!this.user || !this.employeeId || !this.project || !this.service || !this.hoursType) {
                throw new Error('user employee_id, project_id, projectservice_id, type_id must de available');
            }
            const data = {
                employee_id: this.employeeId,
                hours: this.hours,
                is_time_defined: false,
                start_date: this.dateValue,
                project_id: this.project,
                projectservice_id: this.service,
                type_id: this.hoursType,
            };
            if (this.comment && this.comment.length) {
                data.note = this.comment;
            }
            if (this.jiraIssue) {
                data.custom_fields = [{
                    external_url: `${this.jiraUrl}/browse/${this.jiraIssue}`,
                }];
            } else if (data.note) {
                // TODO: remove this hardcode stuff :P
                const gvJiraMatch = data.note.match(/(GVMJ-\d+)/);
                if (gvJiraMatch) {
                    data.custom_fields = [{
                        external_url: `https://jira.grandvision.global/browse/${gvJiraMatch[1]}`,
                    }];
                }
            }

            if (this.settings.confirmPosts && !window.confirm(`Are you sure you want to send ${JSON.stringify(data)}?`)) {
                console.warn('Cancelling Simplicate submit', data);
                return;
            }
            const method = this.id ? 'put' : 'post';
            let url = `${this.settings.apiUrl}/hours/hours`;
            if (this.id) {
                url += `/${this.id}`;
            }
            return axios(Object.assign({method, url, data}, this.apiConfig))
                .then(resp => {
                    console.info('Registered hours at Simplicate as %s', resp.data.data ? resp.data.data.id : null);
                    console.debug(resp);
                    return resp;
                });
        },
        deleteHours (id, hours) {
            id = id || hours.id;
            return axios.delete(`${this.settings.apiUrl}/hours/hours/${id}`, this.apiConfig)
                .then(resp => {
                    console.info('Deleted hours at Simplicate %s', id);
                    console.debug(resp);
                    return resp;
                })
                // TODO: show alert on error
                .finally(() => {
                    this.fetchHours();
                });
        },
        postJiraWorklog () {
            if (!this.isValid) {
                throw new Error('Form must be valid before it can be submitted to Simplicate');
            }
            if (!this.jiraIssue) {
                throw new Error('Jira issue key must be set');
            }

            // https://developer.atlassian.com/cloud/jira/platform/rest/v2/api-group-issue-worklogs/#api-rest-api-2-issue-issueidorkey-worklog-post
            const content = {
                timeSpentSeconds: Math.round(this.hours * 3600),
            };
            const comment = this.comment ? `${this.comment}`.trim(): null;
            if (comment && comment.length) {
                content.comment = comment;
            }
            if (!this.isToday && this.settings.jiraSetStarted && this.date) {
                content.started = this.date.toISOString();
            }

            const url = `${this.settings.jiraApiUrl}/issue/${this.jiraIssue}/worklog`;

            if (this.settings.confirmPosts && !window.confirm(`Are you sure you want to send ${JSON.stringify(content)} to ${url}?`)) {
                console.warn('Cancelling Jira submit', content);
                return;
            }
            return axios.post(url, content, this.jiraConfig)
                .then(resp => {
                    console.info('Added worklog at Jira as #%d; %s', resp.data.id || null, resp.data.self || null);
                    console.debug(resp);
                    return resp;
                });
        },
        getRegexes (str) {
            return (str || '')
                .split('\n')
                .filter(val => val)
                .map(pattern => {
                    try {
                        return new RegExp(pattern, 'iu');
                    } catch {
                        return null;
                    }
                })
                .filter(regex => regex);
        },
        stepDate (days) {
            if (this.date && days) {
                this.date.setDate(this.date.getDate() + days);
                this.date = new Date(this.date);
            }
        },
        nextDay () {
            this.stepDate(+1);
        },
        prevDay () {
            this.stepDate(-1);
        },
        focusProjectSelect () {
            this.$refs.projectSelector.$refs.search.focus();
        },
        focusServiceSelect () {
            this.$refs.serviceSelector.$refs.search.focus();
        },
        focusHoursTypeSelect () {
            this.$refs.hoursTypeSelector.$refs.search.focus();
        },
        focusHoursInput () {
            this.$refs.hoursInput.focus();
        },
        focusJiraIssueSelect () {
            this.$refs.jiraIssueSelector.$refs.search.focus();
        },
        focusCommentInput () {
            this.$refs.commentInput.focus();
        },
    },
    mounted () {
        if (!this.user && this.authKey && this.authSecret) {
            this.fetchUser();
        }
        // 'autofocus'
        if (this.settings.autofocusProjectSelect && !this.project) {
            // FIXME: this doesn't work because the project selector isn't rendered until the user is set
            this.focusProjectSelect();
        }
        if (this.authKey && this.authSecret) {
            this.fetchAllEmployees();
        }
        this.fetchJiraUser();
    },
    created () {
        window.addEventListener('popstate', this.onPopState);
        window.addEventListener('keyup', this.onKeyUp);
        this.debounceFetchJiraIssues = debounce(this.fetchJiraIssues, this.settings.jiraDebounce || defaultSettings.jiraDebounce);
        if (this.jiraProjects.length === 0) {
            this.fetchJiraProjects();
        }
    },
    destroyed () {
        window.removeEventListener('popstate', this.onPopState);
        window.removeEventListener('keyup', this.onKeyUp);
    }
}
</script>
