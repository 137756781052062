<template>
    <div>
        <div class="alert alert-info">
            <!-- TODO: add some explanation about project mapping -->
            <strong>TODO:</strong> some info here
        </div>
        <div class="row">
            <div class="col"><strong>Simplicate</strong></div>
            <div class="col"><strong>Jira</strong></div>
        </div>
        <div v-for="([simplicate, jira], index) in value" :key="index" class="row mt-3">
            <div class="col">
                <v-select
                    placeholder="Simplicate Project"
                    multiple
                    required
                    :value="simplicate"
                    @input="onSimplicateInput(index, $event)"
                    :options="simplicateProjects"
                    :selectable="selectableCb(simplicate)"
                    label="name"
                    :reduce="p => p.id"
                    :select-on-tab="true"
                    :clear-search-on-blur="() => true"
                    :clear-search-on-select="false"
                    :close-on-select="false"
                >
                    <template #option="option">
                        <code class="me-2">{{ option.project_number }}</code>
                        <span class="me-2">{{ option.project_name.replace(/\s*\(\d+\)$/, '') }}</span>
                        <span class="text-muted">{{ option.organization ? option.organization.name : '' }}</span>
                    </template>
                    <template #selected-option="option">
                        <span
                            v-if="option.project_name"
                            :title="`${option.project_name} - ${option.organization ? option.organization.name : ''}`.trim()"
                        >{{ option.project_name.replace(/\s*\(\d+\)$/, '') }}</span>
                        <template v-else>{{ option.name }}</template>
                    </template>
                </v-select>
            </div>
            <div class="col">
                <v-select
                    placeholder="Jira Project"
                    multiple
                    required
                    :value="jira"
                    @input="onJiraInput(index, $event)"
                    :options="jiraProjects"
                    :selectable="selectableCb(jira)"
                    label="name"
                    :reduce="p => p.id"
                    :select-on-tab="true"
                    :clear-search-on-blur="() => true"
                    :clear-search-on-select="false"
                    :close-on-select="false"
                >
                    <template #option="option">
                        <code class="me-2">{{ option.key }}</code>
                        <span class="me-2">{{ option.name }}</span>
                        <code class="text-muted">({{ option.projectKeys.filter(k => k !== option.key).join(', ') }})</code>
                    </template>
                </v-select>
            </div>
            <div class="col-auto">
                <button type="button" @click="confirmRemoval(index)" class="btn btn-sm btn-outline-danger" title="Remove entry"><i class="bi-trash"></i></button>
            </div>
        </div>
        <div class="mt-3">
            <button type="button" @click="addNew" class="btn btn-primary w-100">Add new mapping</button>
        </div>
        <div class="mt-3">
            <button type="button" @click="exportToClipboard($event)" class="btn btn-outline-secondary">
                Export <i class="ms-1 bi" :class="justExported ? 'bi-check' : 'bi-clipboard'"></i>
            </button>
            <button type="button" @click="importPrompt" class="btn btn-outline-secondary">
                Import <i class="ms-1 bi bi-box-arrow-in-down"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectMapper',
    props: {
        value: {
            type: Array,
            required: true,
        },
        simplicateProjects: {
            type: Array,
            required: true,
        },
        jiraProjects: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            justExported: false,
        };
    },
    methods: {
        addNew () {
            this.value.push([[],[]]);
            this.$nextTick(() => {
                // TODO: focus project selector in last row
            });
            this.emitInput();
        },
        onSimplicateInput (index, projects) {
            this.value[index].splice(0, 1, projects);
        },
        onJiraInput (index, projects) {
            this.value[index].splice(1, 1, projects);
        },
        selectableCb (selected) {
            return option => { return !selected.includes(option.id); };
        },
        confirmRemoval (index) {
            const [s, j] = this.value[index];
            if ((s.length || j.length) && !confirm('Are you sure?')) {
                return;
            }
            this.value.splice(index, 1);
            this.emitInput();
        },
        exportToClipboard (event) {
            navigator.clipboard
                .writeText(JSON.stringify(this.value))
                .then(() => {
                    this.justExported = true;
                    event.target.blur();
                    setTimeout(() => this.justExported = false, 5000);
                });
        },
        importPrompt () {
            const json = window.prompt('Paste your mapping JSON here...', '[]');
            try {
                // TODO: should we validate the structure?
                this.value = JSON.parse(json);
                this.emitInput();
            } catch {
                window.alert('Could not parse or import mapping');
            }
        },
        emitInput () {
            this.$emit('input', this.value);
        }
    },
}
</script>
