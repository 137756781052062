<template>
    <div>
        <!-- TODO: Fix code flow and remove stuff below -->
        <div class="alert alert-warning">
            <strong>Stuff's broken!</strong>
            The steps below don't seem to work (yet)! Please provide your authentication key and secret below.
            (Inspect your network traffic with Simplicate when interacting with the browser extension).
        </div>
        <div class="row">
            <div class="col-md">
                <input type="text" v-model.lazy="authKey" @change="handleAuthChange" class="form-control" placeholder="Authentication key">
            </div>
            <div class="col-md">
                <input type="text" v-model.lazy="authSecret" @change="handleAuthChange" class="form-control" placeholder="Authentication secret">
            </div>
            <div class="col-md-auto">
                <button type="button" @click="handleAuthChange" class="btn btn-primary">Submit</button>
            </div>
        </div>
        <hr class="my-5">

        <section>

        <!-- TODO: Fix code flow and remove stuff above ;) (and </section>) -->

        <p class="lead">
            We need to get access to your Simplicate account. This will be done via the simple OAuth2 'code flow'.
            Because we don't have this app registered with Simplicate and therefore no 'own' client id/secret we're
            'borrowing' those api credentials from the official <a href="https://chrome.google.com/webstore/detail/simplicate/lnmfbaobeliahpkiakfgfephfechelhn?hl=nl" class="link-secondary">Simplicate Chrome extension</a>.
            <br>
            This will mean the setup is a little different that you might be use to 😉. Still, it's only 3 simple steps:
        </p>

        <p>You don't have to install Chrome and/or the Simplicate Extension!</p>

<!--        <label for="apiUrl" class="form-label">Simplicate API URL</label>-->
<!--        <input type="url" v-model.lazy="settings.apiUrl" id="apiUrl" required class="form-control">-->

        <ol>
            <li :class="{'text-decoration-line-through': authStarted}">Click <a :href="simplicateAuthUrl" @click="authStarted = true" :disabled="!simplicateAuthUrl" target="_blank" rel="noreferrer">here</a> to start the auth flow in a new tab.</li>
            <li>
                Login and grant the Chrome extension Simplicate access.
                <br>
                <em>The browser should now redirect to <span>{{ redirectUri }}?state=nope&code=...</span>, which won't be able to load.</em>
            </li>
            <li>Extract the <code>code</code> value from query string of the redirect url and enter it below.<!-- Click submit.--></li>
        </ol>
        <input type="text" v-model="simplicateAuthCode" :class="{'is-valid': validCode}" class="form-control" placeholder="Code from redirect url..." pattern="[A-Za-z0-9]{32}">
<!--        <button type="button" @click="submitSimplicateAuthCode" :disabled="!validCode" class="btn btn-secondary">Check Code</button>-->

        </section>
    </div>
</template>

<script>

import axios from 'axios';

// Stolen from Simplicate Chrome extension (lnmfbaobeliahpkiakfgfephfechelhn)...
const clientId = process.env.VUE_APP_SIMPLICATE_CLIENT_ID;
const clientSecret = process.env.VUE_APP_SIMPLICATE_CLIENT_SECRET;
const redirectUri = process.env.VUE_APP_SIMPLICATE_REDIRECT_URL;
const oauthUri = 'https://auth.simplicateapp.com/oauth';
const oauthTokenUri = process.env.VUE_APP_SIMPLICATE_OAUTH_TOKEN_URL || `${oauthUri}/token`;
const codeRegex = /^[A-Za-z0-9]{32}$/;

export default {
    name: 'SimplicateSetup',
    props: {
        settings: {
            type: Object,
            required: true,
        },
        redirectUri: {
            type: String,
            default: redirectUri,
        },
    },
    data () {
        return {
            codeVerifier: null,
            codeChallenge: null,
            simplicateAuthCode: null,
            authStarted: false,
            authKey: null,
            authSecret: null,
        };
    },
    async mounted () {
        if (!this.codeVerifier || !this.codeChallenge) {
            await this.initCodeVerifierAndChallenge();
        }
    },
    computed: {
        simplicateAuthUrl () {
            if (!this.codeChallenge) return;
            const params = new URLSearchParams({
                client_id: clientId,
                code_challenge: this.codeChallenge,
                response_type: 'code',
                code_challenge_method: 'S256',
                state: 'nope',
                redirect_uri: this.redirectUri,
                scope: 'all'
            });
            return `${oauthUri}/authorize?${params.toString()}`;
        },
        validCode () {
            return this.simplicateAuthCode && codeRegex.test(this.simplicateAuthCode);
        },
    },
    watch: {
        validCode (valid) {
            if (valid) {
                this.submitSimplicateAuthCode();
            }
        },
    },
    methods: {
        async initCodeVerifierAndChallenge () {
            const verifierData = new Uint8Array(64);
            crypto.getRandomValues(verifierData);
            // this.codeVerifier = Array.from(verifierData, (dec) => dec.toString(16).padStart(2, "0")).join('');

            this.codeVerifier = this.base64urlEncode(verifierData);
            // console.log('verifier:', this.codeVerifier);

            this.codeChallenge = await this.generateCodeChallenge(this.codeVerifier)
            // console.log('challenge:', this.codeChallenge);

            // this.generateCodeChallenge(this.codeVerifier).then((challenge) => {
            //     this.codeChallenge = challenge;
            // });
            // console.log('done');
        },
        async generateCodeChallenge (code_verifier) {
            const encoder = new TextEncoder();
            const data = encoder.encode(code_verifier);
            return this.base64urlEncode(await crypto.subtle.digest('SHA-256', data));
        },
        base64urlEncode (arr) {
            return btoa(String.fromCharCode.apply(null, new Uint8Array(arr)))
                .replace(/\+/g, "-")
                .replace(/\//g, "_")
                .replace(/=/g, "");
        },
        async submitSimplicateAuthCode () {
            const data = {
                code: this.simplicateAuthCode,
                code_verifier: this.codeVerifier,
                client_id: clientId,
                client_secret: clientSecret,
                grant_type: 'authorization_code',
                redirect_uri: redirectUri,
            };
            axios.post(oauthTokenUri, data)
                .then(resp => {
                    if (!resp.data || resp.data.error || !resp.data.authentication_key || !resp.data.authentication_secret) {
                        console.error(resp.data);
                        throw new Error('Failed to get authentication_key/authentication_secret');
                    }
                    console.log(resp.data);
                    this.$emit('change', resp.data.authentication_key, resp.data.authentication_secret, resp.data.expires_in || null, resp.data);
                });

            // const response = await fetch(oauthTokenUri, {
            //     method: 'POST',
            //     mode: 'cors',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     referrerPolicy: 'no-referrer',
            //     body: JSON.stringify(data),
            // })
            //     .then(response => response.blob())
            //     .catch(err => {
            //     console.error(err);
            //     alert('Failed to get a token from Simplicate.');
            // });
            // console.log(response);
        },
        handleAuthChange () {
            if (this.authKey && this.authSecret) {
                this.$emit('change', this.authKey, this.authSecret, null);
            }
        }
    },
}
</script>
